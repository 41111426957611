.skills {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-content: space-evenly;
  align-items: stretch;
}
.skill {
  width: 3vw;
  height: auto;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.273);
}
@media screen and (max-width: 1025px) {
  .skill {
    width: 3em;
    height: 3em;
    margin-bottom: 10%;
  }
}
