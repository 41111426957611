.menu {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menu-title {
  text-align: center;
  font-size: xx-large;
  margin: 1%;
  color: white;
  text-shadow: 1px 2px 2px #000000e6;
}
.menu-interface {
  margin-bottom: 1%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
}
.menu-button {
  width: 200px;
  height: 50px;
  border-radius: 5px;
  margin: auto;
  color: white;
  font-size: 20px;
  overflow: hidden;
  background-color: transparent;
  z-index: 2;
  box-shadow: 0px 1px 0px 1px black;
}

.liquid {
  position: relative;
  pointer-events: none;
  top: -100px;
  left: -8px;
  width: 200px;
  height: 200px;
  background: #7293ff;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.7);
  z-index: -1;
  transition: 0.6s;
}
.liquid-cv {
  background: #ff0000;
}
.liquid-scolaire {
  background: rgb(12, 214, 12);
}
.liquid::after,
.liquid::before {
  position: absolute;
  content: '';
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  transform: translate(-25%, -75%);
}

.liquid::after {
  border-radius: 45%;
  background: rgb(20, 20, 20);
  box-shadow: 0 0 10px 5px #7293ff, inset 0 0 5px #7293ff;
  animation: animate 7s linear infinite;
  opacity: 0.8;
}

.liquid::before {
  border-radius: 40%;
  box-shadow: 0 0 10px rgba(26, 26, 26, 0.5),
    inset 0 0 5px rgba(26, 26, 26, 0.5);
  background: rgba(26, 26, 26, 0.5);

  animation: animate 9s linear infinite;
}
.menu-button:hover .liquid {
  top: -120px;
}

@keyframes animate {
  0% {
    transform: translate(-25%, -75%) rotate(0);
  }
  100% {
    transform: translate(-25%, -75%) rotate(360deg);
  }
}

@media screen and (max-width: 1240px) {
  .menu-button {
    width: 180px;
  }
}

@media screen and (max-width: 1130px) {
  .menu-button {
    width: 160px;
  }
  .liquid {
    top: -88px;
  }
}
@media screen and (max-width: 1000px) {
  .menu-button {
    width: 200px;
  }
  .liquid {
    top: -100px;
  }
  .menu-interface {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 500px) {
  .menu-button {
    width: 200px;
  }
  .liquid {
    top: -100px;
    left: -18px;
  }
  .liquid:before,
  .liquid:after {
    width: 200%;
    height: 100%;
  }
}
@media screen and (min-width: 2200px) {
  .menu-title {
    font-size: 4em;
  }
  .menu-button {
    width: 300px;
    height: 60px;
    font-size: 2em;
  }
  .liquid::after,
  .liquid::before {
    width: 190%;
    height: 300%;
  }
  .menu-button:hover .liquid {
    top: -160px;
  }
}
