footer {
  height: 5%;
  text-align: center;
  color: white;
  background: -moz-linear-gradient(top, #27ce39 0%, #ba911a 26%, #8c4a00 100%);
  background: -webkit-linear-gradient(
    top,
    #27ce39 0%,
    #ba911a 26%,
    #8c4a00 100%
  );
  background: linear-gradient(to bottom, #27ce39 0%, #ba911a 26%, #8c4a00 100%);
}
.footer-title {
  margin: 0;
  color: #27ce3800;
}
