.presentation {
  width: 100%;
  height: 100%;
  margin: 0 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-shadow: 4px 3px 3px rgba(0, 0, 0, 0.777);
  backdrop-filter: blur(20px);
  background: #ffffff00;
  border-radius: 25px;
  border: 1px solid #fff;
  box-shadow: 10px 10px 25px #00000044, -10px -10px 25px #00000044;
}
.presentation-text-box {
  width: 100%;
}
.presentation-text {
  font-size: 1.3vw;
  margin: 1% 2%;
}
.photo-name {
  width: 100%;
  text-align: center;
}
.maphoto {
  width: 26%;
  margin: 2%;
  height: auto;
  box-shadow: 14px 10px 8px 0px rgb(0 0 0 / 37%);
  animation: borderShape 4s ease-in-out infinite alternate;
}
.name {
  width: 100%;
  margin-top: 0;
  font-size: 2vw;
}
@keyframes borderShape {
  0% {
    border-radius: 0% 40% 0% 40%;
  }
  100% {
    border-radius: 40% 0% 40% 0%;
  }
}
@media screen and (max-width: 1025px) {
  .maphoto {
    margin: 3%;
  }
  .presentation {
    text-shadow: 2px 2px 1px rgb(0, 0, 0);
    border: 0px;
    box-shadow: #00000000;
  }
  .name {
    font-size: 3vw;
  }
  .presentation-text {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 650px) {
  h1 {
    font-size: 8vw;
  }
  .maphoto {
    width: 35%;
  }
  .name {
    font-size: 5vh;
  }
}
@media screen and (min-width: 2200px) {
  .maphoto {
    width: 30%;
  }
  .presentation-text {
    font-size: 2em;
  }
}
