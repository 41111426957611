.selection {
  width: 99%;
  height: 75vh;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  overflow-x: hidden;
  color: white;
  background-image: url(../Assets/img/stars.gif);
  background-color: rgba(0, 0, 0, 0.768);
}
.selection-default {
  color: #00ff8b;
  animation: bounce 1s infinite alternate;
}
.glassmorphisme {
  backdrop-filter: blur(0.5px);
  background: #ffffff00;
  border-radius: 25px;
  border: 1px solid #ffffffa8;
  box-shadow: 10px 10px 25px #00000044, -10px -10px 25px #00000044;
}
.cv-box {
  width: 80%;
  height: 90%;
  font-size: large;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.cv-box-title {
  font-size: large;
  margin: auto;
}
.cvPng {
  width: 50%;
  height: auto;
  margin: auto;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 20px 10px 5px rgba(0, 0, 0, 0.806);
}
.cv-button {
  height: auto;
  margin: auto;
  border-radius: 5px;
  font-size: 25px;
  color: white;
  background: #00c9ff;
  background: -moz-linear-gradient(left, #00c9ff 0%, #0387ff 100%);
  background: -webkit-linear-gradient(left, #00c9ff 0%, #0387ff 100%);
  background: linear-gradient(to right, #00c9ff 0%, #0387ff 100%);
}
.cv-button:hover {
  background: linear-gradient(to right, #00fff6 0%, #036dff 100%);
}
a {
  width: 80%;
  margin: auto;
  text-align: center;
  text-decoration: none;
}
.download-cv {
  width: 100%;
}
.projets-list-container {
  width: 100%;
  height: 100%;
  text-align: center;
  animation: wtf 3.5s ease-in-out;
}
.projet {
  width: 90%;
  margin: auto;
  margin-bottom: 2%;
}
.projet-img {
  width: 50%;
  height: auto;
  margin: 4%;
  border-radius: 5px;
  box-shadow: 20px 10px 5px rgba(0, 0, 0, 0.806);
}
.projet-title {
  font-size: x-large;
  margin: 0 0 2%;
}
.projet-description {
  color: #ffffffca;
  font-size: 22px;
  margin: 0px 2%;
}
.projet-linkButton {
  width: 80%;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  color: white;
  font-size: 24px;
}
.github-linkButton {
  background: -moz-linear-gradient(45deg, #fb09f7f0 0%, #00fff6 100%);
  background: -webkit-linear-gradient(45deg, #fb09f7f0 0%, #00fff6 100%);
}
.github-linkButton:hover {
  background: -moz-linear-gradient(45deg, #00fff6 0%, #fb09f7f0 100%);
  background: -webkit-linear-gradient(45deg, #00fff6 0%, #fb09f7f0 100%);
}
.website-linkButton {
  background: #09fb56e8;
}
.website-linkButton:hover {
  background: -moz-linear-gradient(45deg, #01b437 0%, #00aaffb4 100%);
  background: -webkit-linear-gradient(45deg, #01b437 0%, #00aaffb4 100%);
}
.selection::-webkit-scrollbar {
  width: 10px; /* Largeur de la barre de défilement */
}

/* Style du curseur (la partie glissante) */
::-webkit-scrollbar-thumb {
  width: auto;
  height: auto;
  background: #2bc102;
  background: -moz-linear-gradient(top, #12ff3e 0%, #007bf5 100%);
  background: -webkit-linear-gradient(top, #12ff36 0%, #007bf5 100%);
  background: linear-gradient(
    to bottom,
    #12ff36 0%,
    #007bf5 100%
  ); /* Couleur de fond du curseur */
  border-radius: 1%; /* Coins arrondis du curseur */
}

/* Style de la piste (l'arrière-plan) */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0); /* Couleur de fond de la piste */
}
@keyframes wtf {
  0% {
    opacity: 0;
  }
  40%,
  80% {
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce {
  100% {
    transform: translate(0, -20%);
  }
}
@media screen and (max-width: 1024px) {
  .cvPng {
    width: 100%;
  }
  .selection {
    width: 99%;
    height: 100vh;
  }
  .cv-button {
    width: 50%;
  }
  a {
    width: 100%;
    text-align: center;
  }
  .download-cv {
    text-align: center;
    width: 50%;
  }
  .projet-linkButton {
    width: 80%;
  }
}
@media screen and (max-width: 800px) {
  .cv-box {
    width: 100%;
  }
  .cvPng {
    width: 84%;
  }
}
.download-cv {
  width: 80%;
}
@media screen and (max-width: 1024px) and( min-height:1500px) {
  .cvPng {
    width: 80%;
  }
}
@media screen and (min-width: 2200px) {
  .cv-box-title {
    font-size: 3em;
  }
  .cvPng {
    width: 30%;
  }
  .open-cv {
    height: 3em;
  }
  a {
    height: 3em;
  }
  .download-cv {
    height: 3em;
  }
  .projet-title {
    font-size: 2em;
  }
  .projet-description {
    font-size: 2em;
  }
  .projet-linkButton {
    font-size: 2em;
  }
}
