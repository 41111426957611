header {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  color: white;
  text-align: center;
  background: #000000;
  background-image: url(../Assets//img/stars.gif);
}
h1 {
  margin: 0;
  padding: 0;
}
.link {
  position: absolute;
  top: 10px;
  right: 2px;
}
.linkedin .gitbub {
  width: 15px;
  height: 15px;
}
.linkedin::before {
  content: "\f08c";
  font-family: fontAwesome;
  color: white;
  font-size: 20px;
  margin: 5px;
}
.github::before {
  content: "\f09b";
  font-family: fontAwesome;
  color: white;
  font-size: 20px;
  margin: 5px;
}
@media screen and (max-width: 650px) {
  h1 {
    font-size: 8vw;
  }
  .link {
    top: -2px;
    display: flex;
    flex-direction: column;
  }
  .linkedin::before {
    font-size: 10px;
    margin: 0;
  }
  .github::before {
    font-size: 10px;
    margin: 0;
  }
}
