.App {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}
* {
  user-select: none;
}
::-webkit-scrollbar {
  width: 0px; /* Largeur de la barre de défilement */
}
.mousse-animation {
  position: absolute;
  pointer-events: none;
  width: 20px;
  height: 20px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  z-index: 10;
}
.mousse-animation::before {
  position: absolute;
  content: '\f005';
  font-family: fontAwesome;
  animation: mouseStar 1s linear forwards;
}
.content-container {
  display: flex;
  align-items: center;
}
button:hover,
.cvPng:hover {
  cursor: pointer;
  scale: 1.1;
  box-shadow: 20px 10px 5px rgba(0, 0, 0, 0.78);
  animation-timing-function: ease-in-out;
  transition-duration: 500ms;
  z-index: 5;
}
@keyframes upAndShadow {
  0% {
  }
  100% {
    scale: 1.1;
    box-shadow: 20px 10px 5px rgba(0, 0, 0, 0.78);
  }
}
@keyframes mouseStar {
  0% {
    opacity: 0;
    color: rgb(255, 255, 255);
    translate: 0px;
    transform: rotateZ(0deg);
  }
  20%,
  80% {
    opacity: 1;
  }
  100% {
    color: rgb(113, 0, 252);
    translate: 100px;
    transform: rotateZ(360deg);
  }
}
@media screen and (max-width: 1024px) {
  .App {
    width: 100%;
    height: auto;
  }
  .content-container {
    flex-direction: column;
  }
}
